/** @jsx jsx */

import { Fragment } from 'react'
import { Flex, jsx } from 'theme-ui'

import benPotter from '../assets/headshots/legal/ben-potter.png'
import cammyContizano from '../assets/headshots/legal/cammy-contizano.png'
import davidConcannon from '../assets/headshots/legal/david-concannon.png'
import dawnBelt from '../assets/headshots/legal/dawn-belt.png'
import ericKlingerWilensky from '../assets/headshots/legal/eric-klinger-wilensky.png'
import gregVolkmar from '../assets/headshots/legal/greg-volkmar.png'
import jasonFlaherty from '../assets/headshots/legal/jason-flaherty.png'
import jeffLaretto from '../assets/headshots/legal/jeff-laretto.png'
import johnBrust from '../assets/headshots/legal/john-brust.png'
import markOblad from '../assets/headshots/legal/mark-oblad.png'
import routes from '../constants/routes'

export const halo = {
  title: `CartaX legal advisory team`,
  route: routes.partnersLegal,
  root: `../../`,
  description: `We’ve partnered with experts in corporate and securities law to shape the future of private markets`,
}

export const intro = {
  ...halo,
  title: `Meet the CartaX legal advisory team`,
}

const members = [
  {
    src: dawnBelt,
    name: `Dawn Belt`,
    title: `Fenwick and West`,
    bio: (
      <div>
        <p>
          Recognized among top women leaders in technology law, Dawn shepherds emerging and high-growth companies
          through key moments in their lifecycle. She assists clients with startup formation, venture financings and
          evaluating exit opportunities and provides guidance on mergers and acquisitions, public offerings, SEC
          compliance and corporate governance.
        </p>

        <p>
          Over the last decade, Dawn has worked on a wide range of tech transactions. These include Bill.com’s $216
          million IPO, Facebook’s $16 billion IPO (the largest tech IPO in history at the time), GoPro’s $490 million
          IPO, ServiceNow’s $241 million IPO, Nimble Storage’s $1.2 billion acquisition by HPE and significant venture
          financings for consumer and enterprise-facing technology companies such as Brandless, Dropbox, Minerva
          Project, Optimizely, Silver Peak Systems and Proterra.
        </p>

        <p>
          Dawn advises company executives and boards of directors on financial reporting and disclosure issues,
          securities offerings and corporate governance matters. She is co-author of the firm’s Gender Diversity Survey,
          a benchmark report on women’s participation at the most senior levels of public technology and life sciences
          companies in the Silicon Valley 150 Index and the Standard & Poor’s 100 Index.
        </p>
      </div>
    ),
  },
  {
    src: johnBrust,
    name: `John Brust`,
    title: `Wilson Sonsini Goodrich & Rosati`,
    bio: (
      <div>
        <p>
          John Brust is a partner in the Seattle office of Wilson Sonsini Goodrich & Rosati. His practice focuses on
          advising technology, life sciences, and emerging growth companies in corporate and securities law.
        </p>

        <p>
          John has advised entrepreneurs and growth companies on a range of topics including company formation, private
          financings, secondary sales, initial public offerings, and other strategic transactions. In addition, he also
          represents investors taking minority or majority equity stakes in high-growth companies.
        </p>
      </div>
    ),
  },
  {
    src: davidConcannon,
    name: `David Concannon`,
    title: `Latham and Watkins`,
    bio: (
      <div>
        <p>
          David Concannon is a partner in Latham & Watkins’ New York office, where he’s a member of the firm’s Emerging
          Companies Practice. David’s practice focuses exclusively on emerging companies, representing both companies
          and venture capital firms. He advises companies from formation, through growth, and exits.
        </p>

        <p>
          David also advises on cryptocurrency and initial coin offering, and serves as co-chair of the firm’s
          Blockchain and Cryptocurrency Task Force. In addition, his pro bono clients have included Liberty & Justice,
          Only Six Degrees, and Be Social Change. He is also an affiliated attorney and guest lecturer with the
          University of Michigan School of Law Entrepreneurship Clinic.
        </p>
      </div>
    ),
  },
  {
    src: cammyContizano,
    name: `Cammy Contizano`,
    title: `Goodwin`,
    bio: (
      <div>
        <p>
          Cammy Contizano is a partner in Goodwin’s Business Law Department and a member of its Private Equity group and
          Impact and Responsible Investing practice. Her practice involves a range of corporate and commercial
          transactions, including mergers, acquisitions, leveraged buyouts, growth equity investments, and venture
          capital financings.
        </p>

        <p>
          In addition, Cammy is an expert in structuring and executing secondary transactions for private companies and
          institutional investors. She represents clients in a variety of industries, including technology, media,
          business services, healthcare, education, consumer products, financial services, biotechnology, and energy.
        </p>
      </div>
    ),
  },
  {
    src: jasonFlaherty,
    name: `Jason Flaherty`,
    title: `Orrick`,
    bio: (
      <div>
        <p>
          Jason Flaherty is a partner in the Orrick San Francisco office and a member of the firm’s Compensation and
          Benefits Group. He advises his clients on the design, implementation, and taxation of compensation plans for
          executives and employees.
        </p>

        <p>
          He also counsels clients on all aspects of employee benefits, like equity compensation and retirement plans,
          with a particular focus on compliance. His practice supports public and private companies. Prior to attending
          law school, Jason served as a Sergeant in the United States Army.
        </p>
      </div>
    ),
  },
  {
    src: ericKlingerWilensky,
    name: `Eric Klinger-Wilensky`,
    title: `Morris, Nichols, Arsht & Tunnell`,
    bio: (
      <div>
        <p>
          Eric is a partner at Morris, Nichols, Arsht and Tunnell where he counsels corporations and special committees
          in the context of potential transactions; aids corporations and investors in structuring capital investments;
          and advises generally on corporate governance issues. He also provides corporate-related advice in the context
          of transaction and governance litigation.
        </p>

        <p>
          Eric is actively involved in both the national corporate legal community and the local Delaware legal
          community. At the national level, he is the former chair of the American Bar Association (“ABA”) Section of
          Business Law Private Equity and Venture Capital Committee, and the co-chair of the ABA Section of Business Law
          Mergers and Acquisitions Committee Task Force on Two-Step Transactions.
        </p>

        <p>
          Eric is a lecturer in Law at the University of Pennsylvania Law School, where he teaches classes on M&A
          contract drafting and venture capital, and serves on the Board of Trustees of the university’s Institute for
          Law and Economics. He also serves on the academic advisory board for Bloomberg Law’s Corporate Transactions
          product. He frequently speaks and writes on emerging issues in Delaware corporate law and serves as an editor
          of the Delaware Corporation Law and Practice annual publication.
        </p>
      </div>
    ),
  },
  {
    src: jeffLaretto,
    name: `Jeff Laretto`,
    title: `Cooley`,
    bio: (
      <div>
        <p>
          Jeff Laretto is a partner in Cooley’s New York City emerging companies group and represents high-growth
          technology companies at all stages of development. He frequently advises on incorporation and organizational
          matters, angel, seed and venture capital investments, general corporate strategy, venture debt transactions,
          strategic investments, liquidity transactions, and mergers and other exit transactions. He also counsels
          venture capital funds and strategic investors in connection with investments.
        </p>
      </div>
    ),
  },
  {
    src: markOblad,
    name: `Mark Oblad`,
    title: `Gunderson Dettmer`,
    bio: (
      <div>
        <p>
          Mark Oblad is Of Counsel at Gunderson Dettmer, where he specializes in corporate law doing U.S. and
          international startup formations, financings and exits. Mark represents a wide variety of technology companies
          from consumer internet, software, telecommunications, and entertainment technology industries, as well as a
          number of leading venture capital firms.
        </p>

        <p>
          Mark was previously an associate with Gunderson Dettmer and left to start Valcu Inc., where he was the Founder
          and CEO. Mark returned to Gunderson Dettmer after time spent at JW Player as the VP of Legal & Finance and
          SmartContract as Head of Operations.
        </p>
      </div>
    ),
  },
  {
    src: benPotter,
    name: `Ben Potter`,
    title: `Latham and Watkins`,
    bio: (
      <div>
        <p>
          Ben Potter is a partner at Latham and Watkins. He advises private and public companies, venture capital and
          private equity firms, and investment banks involved in technology, life sciences, and other growth industries.
          He serves as Global Vice Chair of the Emerging Companies Practice and co-chairs the Technology Industry Group.
        </p>

        <p>
          He guides his clients on topics like financings, mergers, acquisitions, formation issues, equity incentives,
          securities law compliance, and corporate governance. In addition to his legal background, he draws on his
          prior experience at IBM Global Services and two education startups.
        </p>
      </div>
    ),
  },
  {
    src: gregVolkmar,
    name: `Greg Volkmar`,
    title: `Gunderson Dettmer`,
    bio: (
      <div>
        <p>
          Greg Volkmar serves as head of the corporate group and is a partner in Gunderson Dettmer’s New York office. He
          co-chairs the firm’s growth equity practice, representing emerging and growth companies, venture capital
          firms, and growth equity funds.
        </p>

        <p>
          In his company practice, Greg supports companies from formation, through financings, secondary liquidity,
          mergers, acquisitions, and public offerings. In his venture capital and growth equity practice, he supports
          clients’ investment activities in the US and internationally. Greg’s experience includes leading investments
          into companies in nearly 20 jurisdictions worldwide.
        </p>
      </div>
    ),
  },
]

const disclaimer = `The committee’s law-firm members have no legal or fiduciary responsibilities to Carta Capital Markets, LLC (“CCMX”) by virtue of participation on the committee, nor does such participation constitute legal representation of and/or provision of legal advice to CCMX. The Committee is formed as a forum for expert discussion and to provide informal guidance and recommendations to CCMX’s senior management. As such, the law-firm committee members cannot compel CCMX staff to act on their respective recommendations or feedback, and do not have authority to make binding organizational decisions or issue instructions or directives to CCMX or its management.`

export const content = (
  <Fragment>
    {members.map(({ src, name, title, bio }, index) => {
      return (
        <Flex key={name} sx={{ py: 5, px: 4, bg: index % 2 !== 0 ? `gray0_slate8` : `transparent` }}>
          <Flex
            sx={{
              maxWidth: `var(--maxContentWidth)`,
              mx: `auto`,
              px: [0, null, null, `24px`],
              flexDirection: [`column`, null, `row`],
              flex: 1,
            }}
          >
            <img
              {...{ src }}
              alt={`Portrait of ${name}`}
              sx={{
                size: [240, null, 200, 240],
                mr: 4,
                borderRadius: 999,
                objectFit: `cover`,
                alignSelf: [`center`, null, `flex-start`],
                color: `avatarShadow`,
                boxShadow: `0 2px 8px -2px`,
              }}
            />
            <div sx={{ maxWidth: `55ch`, mt: [3, null, null, 0] }}>
              <h2 sx={{ my: 0, fontSize: 5 }}>{name}</h2>
              <h3 sx={{ mt: 2, mb: 0 }}>{title}</h3>
              <div sx={{ my: 0 }}>{bio}</div>
            </div>
          </Flex>
        </Flex>
      )
    })}
    <div sx={{ maxWidth: `var(--maxContentWidth)`, mx: `auto`, py: 5, px: 4 }}>
      <p sx={{ my: 0, fontSize: 1, textAlign: `justify`, color: `gray6_slate3` }}>{disclaimer}</p>
    </div>
  </Fragment>
)
